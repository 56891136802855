import React, {useState, useEffect, useRef} from 'react';
import '../css/progress_responsive.css';
import ProgressInputWalletAddress from '../Components/progressInputWalletAddress.js';
import greydashedline from '../images/grey dashed line.png';
import guideicon from '../images/Guide Icon.png';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.png';
import yellowline from '../images/yellow line.png';
import verticalline from '../images/vertical line.png';
import floatingicon from '../images/Floating Icon.png';
import fixedicon from '../images/Fixed Icon.png';
import {toast, Bounce } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import copyicon from "../images/Copy Icon_white.png";
import txlink from "../images/Arrow Button.png";
import Draggable from "react-draggable";

const Progress=()=>{

    const navigate = useNavigate();

    const [exchangeheading1, setExchangeHeading1]=useState('Exchange In');
    const [exchangeheading2, setExchangeHeading2]=useState('Progress');
    const [subheading1, setSubHeading1]=useState('');
    const [subheading2, setSubHeading2]=useState('');
    const [loadingdotvisibility, setLoadingDotVisibility]=useState(true);
    const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
    const [dragPositionRecipientWalletAddress, setDragPositionRecipientWalletAddress] = useState({ x: 0, y: 0 });
    const [dragDepositAddress, setDragDepositAddress] = useState({ x: 0, y: 0 });

    const txHashRef = useRef(null);
    const recipientWalletRef = useRef(null);
    const depositWalletRef = useRef(null);

    const handleDrag = (e, ui, setPosition, ref) => {
        const parentWidth = ref.current.parentElement.offsetWidth;
        const textWidth = ref.current.offsetWidth;
        const maxDragX = parentWidth - textWidth;

        setPosition((prevPosition) => {
            const newX = prevPosition.x + ui.deltaX;
            return {
                x: Math.min(0, Math.max(newX, maxDragX)),  // Clamping the x position
                y: 0, // We are only moving in the x direction
            };
        });
    };

    function formatCustomDate(isoDateStr) {
        const date = new Date(isoDateStr);
      
        // Get year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
      
        // Get hours, minutes, and seconds
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        // Convert 24-hour time to 12-hour time
        hours = hours % 12 || 12; // If hours is 0, make it 12 (for midnight)
      
        // Format final string as YYYY-MM-DD H:MM:SS AM/PM
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
      
        return formattedDate;
      }
      
      

    const handleDragTxHash = (e, ui) => handleDrag(e, ui, setDragPosition, txHashRef);
    const handleDragRecipientWallet = (e, ui) => handleDrag(e, ui, setDragPositionRecipientWalletAddress, recipientWalletRef);
    const handleDragDepositWallet = (e, ui) => handleDrag(e, ui, setDragDepositAddress, depositWalletRef);


    let apiInterval;
    let handeonetimeerrorstate;

    const handleOneTimeErrorState=(state)=>{
        handeonetimeerrorstate=state;
    }

    const apiCall=async(url, name)=>{


            apiInterval=setInterval(async()=>{
            try {
                const options={
                    method:"POST",
                    headers:{
                      "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        id:sessionStorage.getItem("ordertrackerid")
                    })
                  }
                const response=await fetch(url, options);
                const data=await response.json(response);
                if(data.tx.status=="exchanging" || data.tx.status=="sending" || data.tx.status=="sending_confirmation" || data.tx.status=="hold" || data.tx.status=="hold*" || data.tx.status=="verifying"){

                    if(data.tx.status=="exchanging"){

                        setExchangeHeading1("Exchange In");
                        setExchangeHeading2("Progress");
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                        setLoadingDotVisibility(true);

                    }else if(data.tx.status=="confirming" || data.tx.status=="confirmed" || data.tx.status=="confirmation"){
                        
                        setExchangeHeading1("Confirming");
                        setExchangeHeading2("Deposit");
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                        setLoadingDotVisibility(true);

                    }else if(data.tx.status=="sending" || data.tx.status=="sending_confirmation"){

                        setExchangeHeading1("Sending");
                        setExchangeHeading2("Crypto");
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                        setLoadingDotVisibility(true);

                    }else if(data.tx.status=="hold" || data.tx.status=="hold*"){

                        setExchangeHeading1("Exchange Is On");
                        setExchangeHeading2("Hold");
                        setSubHeading1("Due to AML/KYC procedure, exchange may be delayed.");
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                        setLoadingDotVisibility(true);

                    }else if(data.tx.status=="verifying"){

                        setExchangeHeading1("Verifying");
                        setExchangeHeading2("Deposit");
                        sessionStorage.setItem('txhash', data.tx.tx_hash);
                        sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                        setLoadingDotVisibility(true);

                    }

                }else if(data.tx.status=="finished" || data.tx.status=="success"){
                    sessionStorage.setItem('completionTime', formatCustomDate(data.tx.completion_time));
                    sessionStorage.setItem('txhash', data.tx.tx_hash);
                    sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                    navigate("/success");

                }else if(data.tx.status=="failed" || data.tx.status=="error" || data.tx.status=="blacklist" || data.tx.status=="aml_check_failed" ){
                    console.log("Progress Page");

                    if(data.tx.status=="failed" || data.tx.status=="error"){

                        setExchangeHeading1("Exchange Unsuccessfull");
                        setExchangeHeading2("Refunding");
                        setLoadingDotVisibility(false);

                    }else if(data.tx.status=="blacklist" || data.tx.status=="aml_check_failed"){

                        setExchangeHeading1("AML Check");
                        setExchangeHeading2("Failed");
                        setSubHeading1("The deposit was marked as risky by AML check service.");
                        setLoadingDotVisibility(false);
                    }

                }else if(data.tx.status=="refunded"){

                    console.log("Progress Page");
                    setExchangeHeading1("Deposit");
                    setExchangeHeading2("Refunded");
                    setLoadingDotVisibility(false);
                }
                handleOneTimeErrorState(false);

            } catch (error) {

                    if(!handeonetimeerrorstate){
                        toast.error('Network error! Please refresh.', {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                            transition: Bounce,
                        });
                        handleOneTimeErrorState(true);
                    }
            }
            },2000)
    }

    useEffect(()=>{
        window.scrollTo(0, 0);

        if(sessionStorage.getItem("local_exchange_name")=="changelly"){
            apiCall(process.env.REACT_APP_URL+"/tx/changelly/status", "changelly");
        }else if(sessionStorage.getItem("local_exchange_name")=="changenow"){
            apiCall(process.env.REACT_APP_URL+"/tx/changenow/status", "changenow");
        }else if(sessionStorage.getItem("local_exchange_name")=="simpleswap"){
            apiCall(process.env.REACT_APP_URL+"/tx/simpleswap/status", "simpleswap");
        }else if(sessionStorage.getItem("local_exchange_name")=="changehero"){
            apiCall(process.env.REACT_APP_URL+"/tx/changehero/status", "changehero");
        }else if(sessionStorage.getItem("local_exchange_name")=="exolix"){
            apiCall(process.env.REACT_APP_URL+"/tx/exolix/status", "exolix");
        }else if(sessionStorage.getItem("local_exchange_name")=="letsexchange"){
            apiCall(process.env.REACT_APP_URL+"/tx/letsexchange/status", "letsexchange");
        }else if(sessionStorage.getItem("local_exchange_name")=="stealthex"){
            apiCall(process.env.REACT_APP_URL+"/tx/stealthex/status", "stealthex");
        }else if(sessionStorage.getItem("local_exchange_name")=="godex"){
            apiCall(process.env.REACT_APP_URL+"/tx/godex/status", "godex");
        }

        return () => clearInterval(apiInterval);
    },[]);

    function copyOrderTacker(){
            navigator.clipboard.writeText(sessionStorage.getItem("ordertrackerid"))
            .then(() => {
            toast.success('Order Tracker ID copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          })
          .catch((err) => {
            toast.error('Failed copying Order Tracker ID!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          });
    }

        function copyDepositAddress(){
                navigator.clipboard.writeText(sessionStorage.getItem("depositaddress"))
                .then(() => {
                toast.success('Deposit Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Deposit Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
            
        }

        function copyRecipientAddress(){
                navigator.clipboard.writeText(sessionStorage.getItem("recipientaddress"))
                .then(() => {
                toast.success('Recipient Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Recipient Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
        }

        function copyTxHash(){
            navigator.clipboard.writeText(sessionStorage.getItem("txhash"))
            .then(() => {
            toast.success('Transaction Hash copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          })
          .catch((err) => {
            toast.error('Failed copying Transaction Hash!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          });
    }

    return(<>
    <ProgressInputWalletAddress progress={"progress"}></ProgressInputWalletAddress>
    <div className='enter-address-text-div'>
        <mtspan> </mtspan>
        <span>{exchangeheading1} </span> <span>{exchangeheading2}
        
            <div style={{display: loadingdotvisibility?"inline-block":"none"}} className="progress_loader"></div>
            </span>
            <span>{subheading1}</span>
            <span>{subheading2}</span>
    </div>

    <div className='row adressRow_desktop'>
    <div className='col-6' style={{paddingLeft:"30px"}}>
                <div  style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Deposit Address:</span>
                    <div className='rounded-div'><div className='deposit-address-width'><Draggable axis="x" onDrag={handleDragDepositWallet}  position={{ x: dragDepositAddress.x, y: 0 }} ><span ref={depositWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragDepositAddress.x}px)`}}>{sessionStorage.getItem("depositaddress")}
                    {/* <img src={copyicon} id='thumb-cursor'  onClick={copyDepositAddress}  className='copy-icon'></img> */}
                    </span></Draggable></div></div>
                </div>
                <div className='left-round-div' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Recipient Address:</span>
                    <div className='rounded-div'><div className='recipient-address-width'><Draggable axis="x" onDrag={handleDragRecipientWallet}  position={{ x: dragPositionRecipientWalletAddress.x, y: 0 }} ><span  ref={recipientWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPositionRecipientWalletAddress.x}px)`}}>{sessionStorage.getItem("recipientaddress")}
                    {/* <img src={copyicon} id='thumb-cursor'  onClick={copyRecipientAddress} className='copy-icon'></img> */}
                    </span></Draggable></div></div>
                </div>

                <div className='left-round-div' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Blockchain TX Hash:</span>
                    <div style={{display:"flex", alignItems:"center", marginTop:"13px"}}>
                    <div className='rounded-div-tx'>
                        <div className='txhash-width'>
                            <Draggable axis="x" onDrag={handleDragTxHash}  position={{ x: dragPosition.x, y: 0 }} ><span ref={txHashRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPosition.x}px)`}}>{sessionStorage.getItem("txhash")}</span></Draggable>
                        </div>
                        <img src={copyicon} id='thumb-cursor'  onClick={copyTxHash} className='copy-icon'></img>
                    </div>
                    <a id="thumb-cursor" href={sessionStorage.getItem('txhashlink')}  target="_blank"><img src={txlink} style={{width:"35px", height:"35px"}}></img></a>
                </div>
                
                </div>
                <div style={{paddingRight:"15%", marginTop:"2%"}}>
                <span style={{color:"#EF960F", fontSize:"13px"}}>* Please Note The Exchange Times Depend On How Fast The Network Confirms The Transaction And Generates A New Block On The Blockchain Network. Keep In Mind That Different Coins Have Different Confirmation Times.</span>
                </div>
        </div>
        <div className='col-6'>
            <div>
                <div style={{display:"flex"}}>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Order Tracker ID:</span>
                    <div className='rounded-div'><span>{sessionStorage.getItem("ordertrackerid")}<img src={copyicon} id='thumb-cursor' onClick={copyOrderTacker} className='copy-icon'></img></span></div>
                </div>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Partner:</span>
                    <div style={{marginTop:"15px", width: "240px"}}><img className='img-fluid' src={sessionStorage.getItem("local_exchange_logo")}></img></div>
                </div>
                </div>
            </div>
            <div>
            <div style={{display:"flex", marginTop:"30px"}}>
                <div className='floating-box-progress'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Type:</span>
                    <div className='rounded-div'><div className='trx-type-submit'><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div></div>
                </div>
                </div>
                <div style={{marginLeft:"20px", marginTop:"4%"}}><span style={{color: "#928F8C", fontSize:"13px"}}>* It Is Recommended To Retain Your Order Tracker ID Until The Completion Of The Swap. Should You Have Any Queries, Please Do Not Hesitate To Reach Out To Your Support Team.</span></div>
                <a style={{color:"#EF960F", fontSize:"13px", marginLeft:"20px"}}>suppot@coinoswap.com</a>
            </div>
        </div>
    </div>
    <div className='row adressRow_mobile'>
    <div className='col-12' style={{paddingLeft:"30px"}}>
                <div  style={{width:"fit-content"}} className='adress_deposit'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Deposit Address:</span>
                    <div className='rounded-div'><div className='deposit-address-width'><Draggable axis="x" onDrag={handleDragDepositWallet}  position={{ x: dragDepositAddress.x, y: 0 }} ><span ref={depositWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragDepositAddress.x}px)`}}>{sessionStorage.getItem("depositaddress")}
                    {/* <img src={copyicon} id='thumb-cursor'  onClick={copyDepositAddress}  className='copy-icon'></img> */}
                    </span></Draggable></div></div>
                </div>
                <div className='left-round-div adress_recipient' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Recipient Address:</span>
                    <div className='rounded-div'><div className='recipient-address-width'><Draggable axis="x" onDrag={handleDragRecipientWallet}  position={{ x: dragPositionRecipientWalletAddress.x, y: 0 }} ><span  ref={recipientWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPositionRecipientWalletAddress.x}px)`}}>{sessionStorage.getItem("recipientaddress")}
                    {/* <img src={copyicon} id='thumb-cursor'  onClick={copyRecipientAddress} className='copy-icon'></img> */}
                    </span></Draggable></div></div>
                </div>

                <div className='left-round-div TX_Hash' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Blockchain TX Hash:</span>
                    <div style={{display:"flex", alignItems:"center", marginTop:"13px"}}>
                    <div className='rounded-div-tx'>
                        <div className='txhash-width'>
                            <Draggable axis="x" onDrag={handleDragTxHash}  position={{ x: dragPosition.x, y: 0 }} ><span ref={txHashRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPosition.x}px)`}}>{sessionStorage.getItem("txhash")}</span></Draggable>
                        </div>
                        <img src={copyicon} id='thumb-cursor'  onClick={copyTxHash} className='copy-icon'></img>
                    </div>
                    <a id="thumb-cursor" href={sessionStorage.getItem('txhashlink')}  target="_blank"><img src={txlink} style={{width:"35px", height:"35px"}}></img></a>
                </div>
                </div>
                <div style={{paddingRight:"15%", marginTop:"2%"}} className='exchange_note'>
                <span style={{color:"#EF960F", fontSize:"13px"}}>* Please Note The Exchange Times Depend On How Fast The Network Confirms The Transaction And Generates A New Block On The Blockchain Network. Keep In Mind That Different Coins Have Different Confirmation Times.</span>
                </div>
        </div>
        <div className='col-12'>
            <div>
                <div style={{display:"flex"}} className='tracker_div'>
                <div style={{width:"100%", margin:"0px 20px"}} className='tracker_id'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Order Tracker ID:</span>
                    <div className='rounded-div ordertracker_rounded'><span>{sessionStorage.getItem("ordertrackerid")}<img src={copyicon} id='thumb-cursor' onClick={copyOrderTacker} className='copy-icon'></img></span></div>
                </div>
                <div style={{width:"100%", margin:"0px 20px"}} className='partner_exchange'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Partner:</span>
                    <div style={{marginTop:"15px", width: "240px"}} className='local_exchange_img'><img className='img-fluid' src={sessionStorage.getItem("local_exchange_logo")}></img></div>
                </div>
                </div>
            </div>
            <div>
            <div style={{display:"flex", marginTop:"30px"}}>
            <div className='floating-box-progress'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Type:</span>
                    <div className='rounded-div floating_rounded'><div className='trx-type-submit'><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div></div>
                </div>
                </div>
                <div style={{marginLeft:"20px", marginTop:"4%"}} className='recommended_div'><span style={{color: "#928F8C", fontSize:"13px"}}>* It Is Recommended To Retain Your Order Tracker ID Until The Completion Of The Swap. Should You Have Any Queries, Please Do Not Hesitate To Reach Out To Your Support Team.</span></div>
                <a href="mailto:support@coinoswap.com" style={{color:"#EF960F", fontSize:"13px", marginLeft:"20px"}} target='_blank' className='mail_support'>support@coinoswap.com</a>
            </div>
        </div>
    </div>

    <div className='recipient-input-how-to'><span>How To Swap Crypto</span><span>Coinoswap Is A Non-Custodial Crypto Exchange Aggregator Providing </span><span>Convenient And Speedy Exchanges To Swap Pairs.</span></div>
    <div className='three-and-four-step'>
        <div><img src={four}></img><div><span>Step 4</span><span>Send The Required Deposit</span><span>To The Designated</span><span>Address</span></div></div>
        <div><img className='img-fluid' src={yellowline}></img><img className='img-fluid' src={verticalline} style={{display:"none"}}></img></div>
        <div><img src={five}></img><div><span>Step 5</span><span>Wait Until Your Swap</span><span>Is Successfully</span><span>Completed.</span></div></div>
    </div>
    </>);
}

export default Progress;