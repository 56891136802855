import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import '../css/home_responsive.css';
import ExchangeBox from '../Components/exchangebox.js';
import our_process from '../images/Our Process.png';
import why_choose from '../images/Why Choose Us.png';
import changenow from '../images/changenow.png';
import changelly from '../images/changelly.png';
import changehero from '../images/changehero.png';
import exolix from '../images/exolix.png';
import godex from '../images/godex.png';
import letsexchange from '../images/letsexchange.png';
import simpleswap from '../images/simpleswap.png';
import stealthex from '../images/stealthex.png';
import why_choose_us_mobile from '../images/why-choose-us-mobile.png';
import how_it_works_mobile from '../images/how-it-works-mobile.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import get_wallet from "../images/Get Your Wallet Button.png";
import order_tracker from "../images/Order tracker Button.png"
import {toast, Bounce } from 'react-toastify';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useNavigate } from 'react-router-dom';
import changenow_black from '../images/changenow.png';
import changelly_black from '../images/changelly.png';
import changehero_black from '../images/changehero.png';
import exolix_black from '../images/exolix.png';
import godex_black from '../images/godex.png';
import letsexchange_black from '../images/letsexchange.png';
import simpleswap_black from '../images/simpleswap.png';
import stealthex_black from '../images/stealthex.png';
import order_tracker_vertical from "../images/Order Tracker_vertical.png"




const Home=(props)=>{

  function setSendIndexfun(index){
    console.log("send function cliecked: ", index)
    props.offerSendIndexSet(index);
  }

  function setGetIndexfun(index){
    props.offerGetIndexSet(index)
  }

  //order Tracker js
  const [isOpen, setIsOpen] = useState(false);

  const toggleOrderTracker = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const[orderid, setOrderId]=useState("");

  //Swap Tracker Warning
  const notify=async()=>{
    
    if(orderid.length==0){
    toast.warn('Please enter complete order id', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      autoDismiss: true,
      theme: "dark",
      transition: Bounce,
    })

    }else{
      try {
        const url=process.env.REACT_APP_URL+"/tx/status";
        const options={
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({id:orderid})
        }
      
      const response=await fetch(url, options);
      const data=await response.json();
      //This if statement will exicute if transaction exists in database
      if(data.message==="Transaction Found"){

        //This If statement will exicute if there is tx_hash exists
        if(data.tx.tx_hash!=null){
          console.log("tx hash exists")
          //This if statement will exicute if transaction is in process or failed
          if(data.tx.status=="exchanging" || data.tx.status=="sending" || data.tx.status=="sending_confirmation" || data.tx.status=="hold" || data.tx.status=="hold*" || data.tx.status=="verifying" || data.tx.status=="failed" || data.tx.status=="error" || data.tx.status=="blacklist" || data.tx.status=="aml_check_failed" || data.tx.status=="refunded"){
            sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
            sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
            sessionStorage.setItem('txhash', data.tx.tx_hash);
            sessionStorage.setItem("depositaddress", data.tx.deposit_address);
            sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
            sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
            sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
            sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name);
            sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
            sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
            sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
            sessionStorage.setItem("local_get_amount", data.tx.get_amount);
            sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
            navigate("/progress")

        //This else if statement will exicute if transaction has successfully processed
        }else if(data.tx.status=="finished" || data.tx.status=="success"){
          sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
          sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
          sessionStorage.setItem('txhash', data.tx.tx_hash);
          sessionStorage.setItem("depositaddress", data.tx.deposit_address);
          sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
          sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
          sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
          sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name); 
          sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
          sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
          sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
          sessionStorage.setItem("local_get_amount", data.tx.get_amount);
          sessionStorage.setItem('completionTime', formatCustomDate(data.tx.completion_time).txtime);
          sessionStorage.setItem('completionDate', formatCustomDate(data.tx.completion_time).txdate);
          sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
          navigate("/success")

        // Incase deposit has not been performed
        }else if(data.tx.status=="waiting" || data.tx.status=="wait" || data.tx.status=="new"){
          sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
          sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
          sessionStorage.setItem('txhash', data.tx.tx_hash);
          sessionStorage.setItem("depositaddress", data.tx.deposit_address);
          sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
          sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
          sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
          sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name); 
          sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
          sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
          sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
          sessionStorage.setItem("local_get_amount", data.tx.get_amount);
          sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
          navigate("/submit")

        // Incase status doesnot match above provided statuses then this else statement will process and show user transaction not found message
        }else{
          toast.error('Transaction Not Found!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
            });
        }

      // This Else statement will exicute if tx_hash field is empty
      }else{
        toast.error('Transaction Not Found!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
          });
      }

      // This else statement will exicute if transaction doesnot exists in database
      }else
      {
        console.log("Here I am");
        
        toast.error('Transaction Not Found!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
      }

      } catch (error) {
        toast.error('Network Error!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
      }
    }

  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  function formatCustomDate(isoDateStr) {
    const date = new Date(isoDateStr);
  
    // Get year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Get hours, minutes, and seconds
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert 24-hour time to 12-hour time
    hours = hours % 12 || 12; // If hours is 0, make it 12 (for midnight)
  
    // Format final string as YYYY-MM-DD H:MM:SS AM/PM
    const txdate=`${year}-${month}-${day}`;
    const txtime= `${hours}:${minutes}:${seconds} ${ampm}`
    const formattedDate = {
      txtime:txtime,
      txdate:txdate
    }
    return formattedDate;
  }

  let imageHeight="70px";

  useEffect(() => {
    
    window.scrollTo(0, 0);
    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

return(
    <div className="container-fluid home-page">
        <div className="section1-home row">
            <div className="col-xl-6 section1-home-heading-div">
                <h1 className="section1-home-headding"><span>Freedom To </span>
                <span style={{color:"#F0970D"}}>Choose </span> <span>Your </span>
                <span>Exchange</span></h1>
                <p style={{color:"#4B4A49", fontSize:"1.4vw", marginBottom:"3%"}}>Best Rates <span style={{letterSpacing:"-3px"}}>---</span> Infinite Swaps</p>
                <div className="row wallet-ordertracker-div">
                  <div className="col-6 get-wallet-container" style={{paddingLeft:"0px"}}>
                    <Link to="/available_soon"><img className="img-fluid get-wallet" src={get_wallet} style={{width:"70%"}} id="thumb-cursor"></img></Link>
                  </div>
                  {/* 
                  <div className="col-6">
                    <div className="dropdown-menu " id="dropdown-tracker-menu-2" aria-labelledby="dropdownMenuLink" style={{borderRadius:"15px"}}>
                      <input type="txt" className="form-control" id="swap-tracker-input" aria-describedby="emailHelp" placeholder="Enter Order Tracker ID" value={orderid} onChange={(e)=>{setOrderId(e.target.value.replace(/\s+/g, ''))}}></input>
                      <button type="submit" className="btn btn-primary swap-track-btn basement-font" onClick={notify}>Track Your Swap</button>
                    </div>
                  <a href="#"  id="dropdownMenuLink" data-toggle="dropdown"  aria-expanded="false"><img className="img-fluid track-order-btn" src={order_tracker} style={{width:"60%", marginTop:"2.5%"}}></img></a>
                  </div>
                  */}
                </div>
            </div>
            <div className="col-xl-6 col-lg-12 exchangebox-align">
              <div className="order-tracker-container">
                  <div className={`order-tracker-btn-wrapper ${isOpen ? 'open' : ''}`}>
                    {/* Image used as button to trigger order tracker */}
                    <img
                      src={order_tracker_vertical}
                      alt="Order Tracker Icon"
                      className="tracker-logo"
                      onClick={toggleOrderTracker}
                      style={{ cursor: 'pointer' }}
                    />
                    <div className={`tracker-content ${isOpen ? 'visible' : ''}`}>
                      <input
                        type="text"
                        className="form-control tracker-input"
                        placeholder="Enter Order Tracker ID"
                        value={orderid}
                        onChange={(e)=>{setOrderId(e.target.value.replace(/\s+/g, ''))}}
                      />
                      <button className="btn btn-warning tracker-btn" onClick={notify}>Track Your Swap</button>
                    </div>
                  </div>
                </div>
              <div className="exchangebox-wrapper">
                <ExchangeBox setSendIndexfun={setSendIndexfun} setGetIndexfun={setGetIndexfun}/>
              </div>
            </div>
        </div>
        <div className="our-process-div">
          <div className="our-process-desktop"><img className="img-fluid" src={our_process}></img></div>
            
            <div className="how-it-works-div">
              <h3>How To Swap Crypto</h3>
              <p>CoinoSwap Is A Non-Custodial Crypto Exchange Aggregator Providing Convenient And Speedy Exchanges To Swap Pairs.</p>
            </div>
            <img className="our-process-mobile" src={how_it_works_mobile} style={{width:"100%"}}></img>
        </div>
        <div className="why-coinoswap-div">
            <h3 className="why-coinoswap-heading">Why Coinoswap Is Your</h3>
            <h3 className="why-coinoswap-heading">Best Option</h3>
            <p className="why-coinoswap-para">CoinoSwap Provides A Seamless And Convenient Service For Swapping Crypto At The Best Rates. With Continuous Monitoring Of Optimal Rates From Various Exchanges, Users Can Effortlessly Select The Most Cost-Effective Option Without The Hassle Of Navigating Multiple Crypto Exchanges. The Exchange Process Is Streamlined To Ensure A Straightforward User-Friendly Experience Without Any Additional Fees. Transaction Fees Are Set By Our Exchange Partners With No Extra Hidden Fees For Using CoinoSwap</p>
            <img className="why-choose-us-img-desktop" src={why_choose} style={{width:"100%"}}></img>
            <img className="why-choose-us-img-mobile" src={why_choose_us_mobile} style={{width:"100%"}}></img>
        </div>

        <div className="partners-slider" style={{marginTop:"6.5%", marginLeft:"15%", marginRight:"15%"}}>
            <h3 className="why-coinoswap-heading">
                Join Our Partners
            </h3>

            <Swiper
            style={{marginTop:"2.8%"}}
            slidesPerView={(screenWidth>993?5:2)}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2600,
              disableOnInteraction: false,
            }}
            // autoplay={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={godex}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide >
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changelly}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={stealthex}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changenow}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changehero}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={simpleswap}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={letsexchange}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={exolix}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changehero}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide >
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changelly}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={stealthex}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={godex}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={simpleswap}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={changenow}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={letsexchange}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div style={{display:"flex", alignItems:"center", height:imageHeight}}>
              <img
                src={exolix}
                className="slider-img"
                style={{ width: "100%" }}
                alt=""
              />
              </div>
            </SwiperSlide>
          </Swiper>

        </div>
    </div>
);
}

export default Home;