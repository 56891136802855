import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
import Navbar from './Components/navbar.js';
import BackgroundImage from './Components/background.js';
import Footer from './Components/footer.js';
import Home from './Pages/Home.js';
import Offers from './Pages/Offers.js';
import Deposit from './Pages/Deposit.js';
import Submit from './Pages/SubmitDeposit.js';
import { setItem } from './Js/localstorage.js';
import Progress from './Pages/Progress.js';
import Success from './Pages/Success.js';
import HowItWorks from './Pages/How-it-works.js';
import AboutUS from './Pages/About_us.js';
import FAQ from './Pages/FAQ.js';
import OurCurrencies from './Pages/Our_Currencies.js';
import Contact_us from './Pages/Contact_us'; 
import Listing_Your_Exchange from './Pages/Listing_Your_Exchange.js';
import Privacy_Policy from './Pages/Privacy_Policy.js';
import Terms_of_use from './Pages/Terms_of_use.js';
import AvailableSoon from './Pages/AvailableSoon.js';
import Affiliate_Program from './Pages/Affiliate_Program.js';
import axios from 'axios';


function App() {

  const [sendindex, setSendIndex]=useState(localStorage.getItem("local_send_index"));
  const [getindex, setGetIndex]=useState(localStorage.getItem("local_get_index"));
  const [CoinMarketData, setCoinMarketData]=useState();
  const [searchCryptoArray, setSearchCryptoArray]=useState();
  const [cryptoData, setCryptoData]=useState();

  function offerSendIndexSet(index){
    localStorage.setItem("local_send_index", index);
    setSendIndex(index);
  }

  function offerGetIndexSet(index){
    localStorage.setItem("local_get_index", index);
    setGetIndex(index);
  }

  useEffect(()=>{
    async function fetchCoinMarketData(){
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response1 = await fetch(process.env.REACT_APP_URL + "/coin_market_data", options);
      const result1 = await response1.json();
      const dataWithIndex = result1.data.map((item, index) => ({
        ...item, // Spread the existing object properties
        tableindex: index // Add the tableindex key with the index as value
      }));

      const url=process.env.REACT_APP_URL+"/currencies";
      const response2=await axios.get(url);
      const result2=response2.data;
      setCoinMarketData(dataWithIndex);
      setSearchCryptoArray(result1.data);
      setCryptoData(result2);
    }
    fetchCoinMarketData();
  },[]);

  return ( 

    <Router scrollBehavior="auto top">
      <BackgroundImage>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home offerSendIndexSet={offerSendIndexSet} offerGetIndexSet={offerGetIndexSet} />}/>
          <Route exact path="/how-it-works" element={<HowItWorks/>} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/our_currencies" element={<OurCurrencies offerSendIndexSet={offerSendIndexSet} offerGetIndexSet={offerGetIndexSet} CoinMarketData={CoinMarketData} searchCryptoArray={searchCryptoArray} cryptoData={cryptoData}/>} />
          <Route exact path="/about_us" element={<AboutUS/>} />
          <Route exact path="/contact_us" element={<Contact_us/>} />
          <Route exact path="/offers" element={<Offers sendindex={sendindex} getindex={getindex}/>} />
          <Route exact path="/deposit" element={<Deposit />} />
          <Route exact path="/submit" element={<Submit />} />
          <Route exact path="/progress" element={<Progress />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/listing_your_exchange" element={<Listing_Your_Exchange />} />
          <Route exact path="/terms_of_use" element={<Terms_of_use />} />
          <Route exact path="/privacy_policy" element={<Privacy_Policy />} />
          <Route exact path="/available_soon" element={<AvailableSoon />} />
          <Route exact path="/affiliate_program" element={<Affiliate_Program />}></Route>
        </Routes>
        <Footer offerSendIndexSet={offerSendIndexSet} offerGetIndexSet={offerGetIndexSet}/>
      </BackgroundImage>
    </Router> 
  );
} 

export default App;
